import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import { urlFor } from "../../sanity"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"
import Loading from "../../components/Loading"
import {
  Flex,
  Box,
  Image,
  Title,
  Text,
  Button,
  Label,
} from "../../components/system"

const Profile = () => {
  const { logout, user, getAccessTokenSilently } = useAuth0()
  const [profile, setProfile] = useState()

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.GATSBY_AUTH0_DOMAIN

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        })

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        const { user_metadata } = await metadataResponse.json()

        console.log(user_metadata)

        setProfile(user_metadata)
      } catch (e) {
        alert(e.message)
      }
    }

    getUserMetadata()
  }, [getAccessTokenSilently, user])

  const meta = async () => {
    const domain = process.env.GATSBY_AUTH0_DOMAIN

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "update:users",
      })

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "PATCH",
        body: JSON.stringify({
          user_metadata: {
            name: "James Dovey",
          },
        }),
      })

      const { user_metadata } = await metadataResponse.json()

      setProfile(user_metadata)
    } catch (e) {
      console.log(e)
    }
  }

  if (!user) {
    return null
  }
  if (!profile) {
    return <Loading />
  }
  return (
    <Layout>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        mt={[3, 6]}
        px={[3, 5]}
        mb={5}
      >
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <Title
            fontWeight="bold"
            fontSize={[6, 7]}
            textAlign="left"
            color="primary"
          >
            {profile.name}
          </Title>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection={["column", "row"]}
        >
          <Box width={["100%", "50%"]}>
            <Image
              src={urlFor(profile.avatar)
                .width(500)
                .height(500)
                .quality(90)
                .url()}
            />
          </Box>

          <Flex flexDirection="column" pl={[0, 5]} pb={[5, 0]}>
            <Flex justifyContent="flex-start" mb={4}>
              <Link to="/community/edit-profile">
                <Button bg="black" color="white" hbg="#404040" hcolor="white">
                  Edit Profile
                </Button>
              </Link>
              <Button onClick={() => logout()} ml={4} outline>
                Logout
              </Button>
            </Flex>
            <Flex flexDirection="column" alignItems="flex-start" mb={4}>
              <Label color="secondary">Bio</Label>
              <Text textAlign="left" pr={[0, 6]}>
                {profile.bio
                  ? profile.bio
                  : "This user has just joined, they will update their bio shortly"}
              </Text>
            </Flex>
            <Flex justifyContent="flex-start">
              {profile.location && (
                <Flex flexDirection="column" alignItems="flex-start">
                  <Label color="secondary">Location</Label>
                  <Text textAlign="left" pr={[0, 6]}>
                    {profile.location}
                  </Text>
                </Flex>
              )}
              {profile.link && (
                <Flex flexDirection="column" alignItems="flex-start">
                  <Label color="secondary">Link</Label>
                  {/* <a href={profile.link} target="_blank" rel="noreferrer"> */}
                  <Text textAlign="left" pr={[0, 6]}>
                    {profile.link}
                  </Text>
                  {/* </a> */}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Profile
